<template>
  <div class="h-auto">
    <HomepageLandingITProvider></HomepageLandingITProvider>
    <!-- <HomepageLandingSolutions></HomepageLandingSolutions> -->
    <!-- <div class="p-2">
      <h2>Solutions</h2>
      <SolutionsGrid query-take="recent"></SolutionsGrid>
      <h2>Services</h2>
      <ServicesGrid query-take="recent"></ServicesGrid>
    </div> -->
    <HomepageLandingClients></HomepageLandingClients>
  </div>
</template>
<script lang="ts" setup>
import { seoConstants } from "~/seoConstants";

useSeoMeta({
  ogTitle: seoConstants.homepage.title,
  description: seoConstants.homepage.description,
  ogDescription: seoConstants.homepage.description,
});

defineOgImageComponent("Sections", {
  title: seoConstants.homepage.title,
  description: seoConstants.homepage.description,
});
</script>
