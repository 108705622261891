<template>
  <div class="relative overflow-hidden">
    <div
      aria-hidden="true"
      class="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
    ></div>

    <div class="relative">
      <div class="max-w-[85rem] mx-auto px-4 sm:px-6 md:px-8 py-10 md:py-16">
        <div class="max-w-2xl text-center mx-auto">
          <!-- Title -->
          <div class="md:mb-2 max-w-2xl">
            <h1
              class="block font-semibold text-gray-800 dark:text-slate-200 text-4xl md:text-5xl lg:text-6xl"
            >
              <span
                class="text-transparent bg-clip-text bg-[linear-gradient(to_right,theme(colors.teal.400),theme(colors.blue.800),theme(colors.orange.400),theme(colors.yellow.400),theme(colors.orange.400),theme(colors.blue.800),theme(colors.teal.400))] bg-[length:200%_auto] dark:bg-[linear-gradient(to_right,theme(colors.teal.400),theme(colors.blue.800),theme(colors.orange.400),theme(colors.yellow.100),theme(colors.orange.400),theme(colors.blue.800),theme(colors.teal.400))] animate-gradient"
                >Trusted & Reliable</span
              >
              <br /><span class="text-blue-800 dark:text-blue-600"
                >IT Solutions Provider</span
              >
            </h1>
          </div>
          <div
            class="w-full h-1 md:h-2 bg-gradient-to-r from-orange-400 to-yellow-100"
          ></div>
          <div class="mt-5 max-w-3xl">
            <p class="text-xl text-gray-600 dark:text-slate-300">
              From your home to the office, we're here to assist you every step
              of the way, delivering customized solutions for all your IT needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
