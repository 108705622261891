<template>
  <Vue3Marquee :clone="true" :duration="comDuration">
    <ClientsClientItem
      :client="client"
      v-for="client of clients"
      :key="client.id"
      :showName="false"
    ></ClientsClientItem>
  </Vue3Marquee>
</template>

<script setup lang="ts">
import { clientFetchQuery } from "~~/app/fetchConstants";
import type { RES_Clients } from "~~/app/fetchConstants";

const clients = ref<RES_Clients | undefined>([]);

const { isMobile } = useDevice();

const comDuration = computed(() => {
  if (isMobile) {
    return 25;
  }
  return 30;
});

onMounted(async () => {
  const res = await $fetch<RES_Clients>(`/api/read/client`, {
    query: clientFetchQuery(15),
  });
  clients.value = res;
});
</script>
